// src/contexts/AuthenticationContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useRecoilState, atom } from 'recoil';
import { authTokenAtom, refreshTokenAtom, userAtom } from '../atoms';  // Importamos desde atoms.js

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useRecoilState(authTokenAtom);
    const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenAtom);
    const [user, setUser] = useRecoilState(userAtom);
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (authTokens) {
            const fetchUser = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/users/me/`, {
                        headers: { Authorization: `Bearer ${authTokens}` }
                    });
                    setUser(response.data);
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                }
                setLoading(false);
            };
            fetchUser();
        } else {
            setLoading(false);
        }
    }, [authTokens, setUser, setLoading]);

    const login = async (email, password, captchaValue) => {
        try {
            const response = await axios.post(`${apiUrl}/users/login/`, { email, password, captchaValue });
            if (response.status === 202) {
                return response; // 2FA requerido
            } else {
                setAuthTokens(response.data.access);
                setRefreshToken(response.data.refresh);
                localStorage.setItem('token', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
                setUser(response.data.user);
                return response; // Login exitoso
            }
        } catch (error) {
            throw new Error('Login failed. Please check your credentials and try again.');
        }
    };

    const logout = () => {
        setAuthTokens(null);
        setRefreshToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setUser(null);
    };

    const validateToken = async () => {
        try {
            let token = authTokens;
            const response = await axios.post(`${apiUrl}/users/token-validate/`, { token });
            return response.data;
        } catch (error) {
            return { error: error.response?.data?.error || "Error validating token" };
        }
    };

    const refreshAuthToken = useCallback(async () => {
        if (authTokens) {
            try {
                const response = await axios.post(`${apiUrl}/users/token-refresh/`, { refresh: refreshToken });
                setAuthTokens(response.data.access);
                localStorage.setItem('token', response.data.access);
            } catch (error) {
                console.error('Failed to refresh token:', error);
            }
        }
    }, [authTokens, refreshToken, setAuthTokens]);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshAuthToken();
        }, 60000); // Refresca el token cada 10 minutos (600000 ms)

        return () => clearInterval(interval);
    }, [refreshAuthToken]);

    return (
        <AuthenticationContext.Provider value={{ authTokens, refreshToken, user, loading, login, logout, validateToken, setAuthTokens, setRefreshToken }}>
            {children}
        </AuthenticationContext.Provider>
    );
};
