import React, { createContext, useState, useContext } from 'react';

// Crear el contexto del modal
const ModalContext = createContext();

// Proveedor del contexto del modal
export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    // Función para abrir el modal con contenido específico
    const openModal = (content) => {
        setModalContent(content);
        setIsOpen(true);
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setIsOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal, modalContent }}>
            {children}
        </ModalContext.Provider>
    );
};

// Hook personalizado para usar el contexto del modal
export const useModal = () => useContext(ModalContext);
