import React from "react";
import classNames from 'classnames';

const MenuSector = ({ id, sectorName, className, selected }) => {
    return (
        <div className={classNames("sc-mcd__filter__item", className, { "sc-mcd__filter__item--selected": selected })}>
            <span
                className="ic-icon ic-commodities"
            ></span>{sectorName}
        </div>
    );
};

export default MenuSector;