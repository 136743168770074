import React from "react";
import classNames from 'classnames';

const Dialog = ({ className, title, enableTabular, onContainerClick, handleCloseDialog, children }) => {
    return (
        <div className={classNames('sc-dialog', className, { 'sc-dialog--tabular': enableTabular })}
            onClick={onContainerClick}>
            {title && (
                <div className='sc-dialog__head'>
                    <div className='sc-dialog__head--title'>{title}</div>
                    <div className="sc-dialog__head--action" onClick={handleCloseDialog}>
                        <span className="ic-icon ">
                            <i className="bi bi-x-lg"></i>
                        </span>
                    </div>
                </div>
            )}
            <div className='sc-dialog__body'>{children}</div>
        </div>
    );
};

export default Dialog