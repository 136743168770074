import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticationContext } from '../contexts/AuthenticationContext';

const ProtectedRoute = ({ children }) => {
    const { authTokens, loading, validateToken } = useContext(AuthenticationContext);
    const [isValid, setIsValid] = React.useState(null); // Estado para manejar la validez del token

    useEffect(() => {
        const checkToken = async () => {
            if (authTokens) {
                const validationResponse = await validateToken();
                setIsValid(!validationResponse.error); // Si no hay error, el token es válido
            } else {
                setIsValid(false); // Si no hay token, no es válido
            }
        };

        checkToken();
    }, [authTokens, validateToken]);

    // Muestra un loader mientras se valida el token
    if (loading) {
        return <div>Loading...</div>; // Aquí puedes agregar un spinner o algo similar
    }

    // Redirige según la validez del token
    if (isValid === false) {
        return <Navigate to="/login" />;
    }

    return children; // Si el token es válido, renderiza los hijos
};

export default ProtectedRoute;
