import React, { useState, useEffect } from 'react';
import SymbolInfo from './SymbolInfo';
import { useRecoilValue } from "recoil";
import { authTokenAtom, selectedAccountAtom, selectedSymbolAtom, symbolInfoTickAtom } from "../atoms";
import NumericInputField from './NumericInputField';
import DateTimePicker from 'react-datetime-picker';
import axios from 'axios';
import classNames from 'classnames';
import 'react-datetime-picker/dist/DateTimePicker.css';
import '../styles/react-datetime-picker.css'
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const TradeControl = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownTypeVisible, setDropdownTypeVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState({ label: 'Market Execution', value: 0 });
    const [selectedType, setSelectedType] = useState({ label: 'Buy Limit', value: 2 });
    const [expirationVisible, setExpirationVisible] = useState(false);
    const [selectedExpiration, setSelectedExpiration] = useState({ label: 'GTC', value: 0 });
    const symbolInfoTick = useRecoilValue(symbolInfoTickAtom);
    const selectedSymbol = useRecoilValue(selectedSymbolAtom);
    const [volumeValue, setVolumeValue] = useState(0.01);
    const [tpValue, setTpValue] = useState(symbolInfoTick?.symbol_info_tick.bid);
    const [slValue, setSlValue] = useState(symbolInfoTick?.symbol_info_tick.bid);
    const [hasTakeProfit, setHasTakeProfit] = useState(false);
    const [hasStopLoss, setHasStopLoss] = useState(false);
    const [expirationDate, setExpirationDate] = useState(new Date());
    const [priceValue, setPriceValue] = useState(symbolInfoTick?.symbol_info_tick.bid);
    const [stopLimitValue, setStopLimitValue] = useState(0.0);
    const authToken = useRecoilValue(authTokenAtom);
    const selectedAccount = useRecoilValue(selectedAccountAtom);

    // Efecto para recargar el componente cuando selectedSymbol cambia
    useEffect(() => {
        setVolumeValue(0.01);
        setTpValue(selectedSymbol.bid);
        setSlValue(selectedSymbol.bid);
        setSelectedOption({ label: 'Market Execution', value: 0 });
        setSelectedType({ label: 'Buy Limit', value: 2 });
    }, [selectedSymbol]);

    const handleOptionSelect = (label, value) => {
        setSelectedOption({ label: label, value: value });
        setDropdownVisible(false);
    };

    const handleTypeSelect = (label, value) => {
        setSelectedType({ label: label, value: value });
        setDropdownTypeVisible(false);
    };

    const handleExpirationSelect = (label, value) => {
        setSelectedExpiration({ label: label, value: value });
        setExpirationVisible(false);
    };

    const createOrder = async (type) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/market-data/orders/`, {
                account: selectedAccount.id, // Debe ser el ID de la cuenta correspondiente
                symbol: selectedSymbol?.id,
                type: type,
                volume_initial: volumeValue,
                volume_current: volumeValue,
                price_open: priceValue,
                price_stop_limit: stopLimitValue,
                sl: hasStopLoss ? slValue : 0,
                tp: hasTakeProfit ? tpValue : 0,
                comment: "",
                time_setup: new Date().toISOString(),
                expiration: expirationDate.toISOString(),
                state: 0 // Estado inicial
            }, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            console.log('Order created:', response.data);
        } catch (error) {
            if (error.response) {
                // Extrae los mensajes de error enviados desde el API
                const errorMessages = error.response.data.errors || error.response.data.error || "Error desconocido";
                console.error('Error creating order:', errorMessages);
                alert(`Error al crear la orden: ${JSON.stringify(errorMessages)}`);
            } else {
                console.error('Error creating order:', error);
                alert(`Error de red o desconocido: ${error.message}`);
            }
        }
    };

    return (
        <div className="sidebar__container">
            <div className="sidebar__items">
                <fieldset className="trade-container__fieldset trade-container__fieldset--advanced">
                    <div className="contract-symbol-widget">
                        <div className='contract-symbol-widget__display'>
                            <SymbolInfo></SymbolInfo>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="trade-container__fieldset trade-types">
                    <div
                        data-testid="dt_contract_widget"
                        id="dt_contract_dropdown"
                        className="contract-type-widget"
                        tabIndex="0"
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                        <div className="contract-type-widget__display">
                            <div className="categories-container contract-type-widget__icon-wrapper">
                                <div className="category-wrapper">
                                    <i className="bi bi-caret-right-fill" style={{
                                        color: "var(--text-profit-success)"
                                    }}></i>
                                </div>
                            </div>
                            <span data-name="contract_type">{selectedOption.label}</span>
                        </div>
                        {dropdownVisible && (
                            <div className="dropdown-menu">
                                <div className="contract-type-widget__display" onClick={() => handleOptionSelect('Market Execution', 0)}><span data-name="contract_type">Market Execution</span></div>
                                <div className="contract-type-widget__display" onClick={() => handleOptionSelect('Pending Order', 1)}><span data-name="contract_type">Pending Order</span></div>
                            </div>
                        )}
                    </div>
                </fieldset>
                {selectedOption.value === 1 && (
                    <fieldset className="trade-container__fieldset trade-types">
                        <div className="contract-type-widget" tabIndex="0" onClick={() => setDropdownTypeVisible(!dropdownTypeVisible)}>
                            <div className="contract-type-widget__display">
                                <div className="categories-container contract-type-widget__icon-wrapper">
                                    <div className="category-wrapper">
                                        <i className="bi bi-caret-right-fill" style={{
                                            color: "var(--text-profit-success)"
                                        }}></i>
                                    </div>
                                </div>
                                <span data-name="contract_type">{selectedType.label}</span>
                            </div>
                            {dropdownTypeVisible && (
                                <div className="dropdown-menu">
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Buy Limit', 2)}>
                                        <span data-name="contract_type">Buy Limit</span>
                                    </div>
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Sell Limit', 3)}>
                                        <span data-name="contract_type">Sell Limit</span>
                                    </div>
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Buy Stop', 4)}>
                                        <span data-name="contract_type">Buy Stop</span>
                                    </div>
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Sell Stop', 5)}>
                                        <span data-name="contract_type">Sell Stop</span>
                                    </div>
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Buy Stop Limit', 6)}>
                                        <span data-name="contract_type">Buy Stop Limit</span>
                                    </div>
                                    <div className="contract-type-widget__display" onClick={() => handleTypeSelect('Sell Stop Limit', 7)}>
                                        <span data-name="contract_type">Sell Stop Limit</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </fieldset>
                )}
                <fieldset className="trade-container__fieldset center-text">
                    <div className="trade-container__fieldset-header">
                        <span className="trade-container__fieldset-info trade-container__fieldset-info--left">Volume</span>
                    </div>
                    <NumericInputField
                        inputProps={{
                            id: 'volumeInput',
                            name: 'volumeValue',
                            type: 'number',
                            placeholder: 'Volume',
                            'aria-label': 'Numeric value',
                        }}
                        minText="Volume Min."
                        maxText="Volume Max."
                        minValue={selectedSymbol.min_volume}
                        maxValue={selectedSymbol.max_volume}
                        step={selectedSymbol.volume_step}
                        value={volumeValue}
                        onChange={setVolumeValue}
                    />
                </fieldset>
                {selectedOption.value === 1 && (
                    <><fieldset className="trade-container__fieldset">
                        <div className="trade-container__fieldset-header">
                            <span className="trade-container__fieldset-info trade-container__fieldset-info--left">Price</span>
                        </div>
                        <NumericInputField
                            inputProps={{
                                id: 'priceInput',
                                name: 'priceValue',
                                type: 'number',
                                placeholder: 'Price',
                                'aria-label': 'Numeric value',
                            }}
                            minValue={-999999999}
                            maxValue={999999999}
                            step={10 ** (-selectedSymbol.digits)}
                            digits={selectedSymbol.digits}
                            value={priceValue}
                            onChange={setPriceValue}
                        />
                    </fieldset>
                        <fieldset className="trade-container__fieldset">
                            <div className="trade-container__fieldset-header">
                                <span className="trade-container__fieldset-info trade-container__fieldset-info--left">Stop Limit Price</span>
                            </div>
                            <NumericInputField
                                inputProps={{
                                    id: 'stopLimitInput',
                                    name: 'stopLimitValue',
                                    type: 'number',
                                    placeholder: 'Stop Limit Price',
                                    'aria-label': 'Numeric value',
                                }}
                                minValue={-999999999}
                                maxValue={999999999}
                                step={(10 ** (-selectedSymbol.digits))}
                                digits={selectedSymbol.digits}
                                value={stopLimitValue}
                                onChange={setStopLimitValue}
                            />
                        </fieldset>
                        <fieldset className="trade-container__fieldset expiration-types">
                            <div className="trade-container__fieldset-header">
                                <span className="trade-container__fieldset-info trade-container__fieldset-info--left">Expiration</span>
                            </div>
                            <div
                                data-testid="dt_expiration_widget"
                                id="dt_expiration_dropdown"
                                className="expiration-type-widget"
                                tabIndex="0"
                                onClick={() => setExpirationVisible(!expirationVisible)}
                            >
                                <div className="expiration-type-widget__display">
                                    <div className="categories-container expiration-type-widget__icon-wrapper">
                                        <div className="category-wrapper">
                                            <i className="bi bi-caret-right-fill" style={{
                                                color: "var(--text-profit-success)"
                                            }}></i>
                                        </div>
                                    </div>
                                    <span data-name="expiration_type">{selectedExpiration.label}</span>
                                </div>
                                {expirationVisible && (
                                    <div className="dropdown-menu">
                                        <div className="expiration-type-widget__display" onClick={() => handleExpirationSelect('GTC', 0)}><span data-name="expiration_type">GTC</span></div>
                                        <div className="expiration-type-widget__display" onClick={() => handleExpirationSelect('Today', 1)}><span data-name="expiration_type">Today</span></div>
                                        <div className="expiration-type-widget__display" onClick={() => handleExpirationSelect('Specified', 2)}><span data-name="expiration_type">Specified</span></div>
                                        <div className="expiration-type-widget__display" onClick={() => handleExpirationSelect('Specified day', 3)}><span data-name="expiration_type">Specified day</span></div>
                                    </div>
                                )}
                            </div>
                        </fieldset>
                        {(selectedExpiration.value === 2 || selectedExpiration.value === 3) && (
                            <fieldset className="trade-container__fieldset">
                                <div className="trade-container__fieldset-header">
                                    <span className="trade-container__fieldset-info trade-container__fieldset-info--left">{selectedExpiration.value === 2 ? 'Expiration Date and Time' : 'Expiration Date'}</span>
                                </div>
                                <div
                                    id="dt_expiration_date"
                                    className="expiration-type-widget"
                                    tabIndex="0"
                                >
                                    <DateTimePicker
                                        onChange={setExpirationDate}
                                        value={expirationDate}
                                        format={selectedExpiration.value === 2 ? "y-MM-dd h:mm:ss a" : "y-MM-dd"}
                                        disableClock={selectedExpiration.value === 3}
                                    />
                                </div>
                            </fieldset>
                        )}
                    </>)}
                <fieldset className="trade-container__fieldset">
                    <div className="dc-input-wrapper--inline">
                        <label htmlFor="dc_take_profit-checkbox_input" className="dc-checkbox take_profit-checkbox__input">
                            <span>
                                <input
                                    className="dc-checkbox__input"
                                    type="checkbox"
                                    id="dc_take_profit-checkbox_input"
                                    name="has_take_profit"
                                    checked={hasTakeProfit}
                                    onChange={() => setHasTakeProfit(!hasTakeProfit)}
                                />
                                <span className={classNames("dc-checkbox__box", { "dc-checkbox__box--active": hasTakeProfit })} tabIndex="0">
                                    {hasTakeProfit && (<i className="bi bi-check-lg"></i>)}
                                </span>
                            </span>
                            <span
                                className="dc-text dc-checkbox__label take_profit-checkbox__label"
                                style={{
                                    '--text-size': 'var(--text-size-xs)',
                                    '--text-color': 'var(--text-general)',
                                    '--text-lh': 'var(--text-lh-unset)',
                                    '--text-weight': 'var(--text-weight-normal)',
                                    '--text-align': 'var(--text-align-left)',
                                }}
                            >
                                Take profit
                            </span>
                        </label>
                    </div>
                    {hasTakeProfit && (
                        <NumericInputField
                            inputProps={{
                                id: 'tpInput',
                                name: 'tpValue',
                                type: 'number',
                                placeholder: 'Take Profit',
                                'aria-label': 'Numeric value',
                            }}
                            minValue={-999999999}
                            maxValue={999999999}
                            step={(10 ** (-selectedSymbol.digits))}
                            digits={selectedSymbol.digits}
                            value={tpValue}
                            onChange={setTpValue}
                        />
                    )}
                </fieldset>
                <fieldset className="trade-container__fieldset">
                    <div className="dc-input-wrapper--inline">
                        <label htmlFor="dc_stop_loss-checkbox_input" className="dc-checkbox stop_loss-checkbox__input">
                            <span>
                                <input
                                    className="dc-checkbox__input"
                                    type="checkbox"
                                    id="dc_stop_loss-checkbox_input"
                                    name="has_stop_loss"
                                    checked={hasStopLoss}
                                    onChange={() => setHasStopLoss(!hasStopLoss)}
                                />
                                <span className={classNames("dc-checkbox__box", { "dc-checkbox__box--active": hasStopLoss })} tabIndex="0">
                                    {hasStopLoss && (<i className="bi bi-check-lg"></i>)}
                                </span>
                            </span>
                            <span
                                className="dc-text dc-checkbox__label stop_loss-checkbox__label"
                                style={{
                                    '--text-size': 'var(--text-size-xs)',
                                    '--text-color': 'var(--text-general)',
                                    '--text-lh': 'var(--text-lh-unset)',
                                    '--text-weight': 'var(--text-weight-normal)',
                                    '--text-align': 'var(--text-align-left)',
                                }}
                            >
                                Stop Loss
                            </span>
                        </label>
                    </div>
                    {hasStopLoss && (
                        <NumericInputField
                            inputProps={{
                                id: 'slInput',
                                name: 'slValue',
                                type: 'number',
                                placeholder: 'Stop Loss',
                                'aria-label': 'Numeric value',
                            }}
                            minValue={-999999999}
                            maxValue={999999999}
                            step={(10 ** (-selectedSymbol.digits))}
                            digits={selectedSymbol.digits}
                            value={slValue}
                            onChange={setSlValue}
                        />
                    )}
                </fieldset>
                <div className="purchase-container">
                    <fieldset className="trade-container__fieldset purchase-container__option">
                        <div className="trade-container__fieldset-wrapper">
                            <div className="trade-container__price">
                                <div
                                    className="dc-popover__wrapper"
                                    data-testid="dt_popover_wrapper"
                                >
                                    <div className="dc-popover__container" style={{ zIndex: '1' }}>
                                        <div
                                            className="dc-popover__container-relative"
                                            data-testid="dt_popover_relative_container"
                                        ></div>
                                    </div>
                                    <div className="dc-popover" id="dt_purchase_call_info">
                                        <div className="dc-popover__target">
                                            <i className="dc-popover__target__icon"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-purchase__shadow-wrapper">
                                <div className="btn-purchase__box-shadow"></div>
                                <button
                                    id="dt_purchase_call_button"
                                    className="btn-purchase btn-purchase--1"
                                    onClick={() => createOrder(0)} // Buy order
                                >
                                    <div
                                        className="btn-purchase__info btn-purchase__info--left"
                                    >
                                        <div className="btn-purchase__type-wrapper">
                                            <div className="btn-purchase__icon_wrapper">
                                                <i className="bi bi-arrow-up-right"></i>
                                            </div>
                                            <div className="btn-purchase__text_wrapper">
                                                <span
                                                    className="dc-text"
                                                    style={{
                                                        '--text-size': 'var(--text-size-xs)',
                                                        '--text-color': 'var(--text-colored-background)',
                                                        '--text-lh': 'var(--text-lh-m)',
                                                        '--text-weight': 'var(--text-weight-bold)',
                                                        '--text-align': 'var(--text-align-left)',
                                                    }}
                                                >Buy</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-purchase__effect-detail"></div>
                                    <div className="btn-purchase__effect-detail--arrow"></div>
                                    <div
                                        className="btn-purchase__info btn-purchase__info--right"
                                    >
                                        <div className="btn-purchase__text_wrapper">
                                            <span
                                                className="dc-text"
                                                style={{
                                                    '--text-size': 'var(--text-size-xs)',
                                                    '--text-color': 'var(--text-colored-background)',
                                                    '--text-lh': 'var(--text-lh-m)',
                                                    '--text-weight': 'var(--text-weight-bold)',
                                                    '--text-align': 'var(--text-align-left)',
                                                }}
                                            >{symbolInfoTick?.symbol_info_tick.ask}</span
                                            >
                                        </div>
                                    </div>
                                </button >
                            </div >
                        </div >
                    </fieldset >
                    <fieldset
                        className="trade-container__fieldset purchase-container__option"
                    >
                        <div className="trade-container__fieldset-wrapper">
                            <div className="trade-container__price">
                                <div
                                    className="dc-popover__wrapper"
                                    data-testid="dt_popover_wrapper"
                                >
                                    <div className="dc-popover__container" style={{ zIndex: '1' }}>
                                        <div
                                            className="dc-popover__container-relative"
                                            data-testid="dt_popover_relative_container"
                                        ></div>
                                    </div>
                                    <div className="dc-popover" id="dt_purchase_put_info">
                                        <div className="dc-popover__target">
                                            <i className="dc-popover__target__icon"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-purchase__shadow-wrapper">
                                <div className="btn-purchase__box-shadow"></div>
                                <button
                                    id="dt_purchase_put_button"
                                    className="btn-purchase btn-purchase--2"
                                    onClick={() => createOrder(1)} // Sell order
                                >
                                    <div
                                        className="btn-purchase__info btn-purchase__info--left"
                                    >
                                        <div className="btn-purchase__type-wrapper">
                                            <div className="btn-purchase__icon_wrapper">
                                                <i className="bi bi-arrow-down-right"></i>
                                            </div>
                                            <div className="btn-purchase__text_wrapper">
                                                <span
                                                    className="dc-text"
                                                    style={{
                                                        '--text-size': 'var(--text-size-xs)',
                                                        '--text-color': 'var(--text-colored-background)',
                                                        '--text-lh': 'var(--text-lh-m)',
                                                        '--text-weight': 'var(--text-weight-bold)',
                                                        '--text-align': 'var(--text-align-left)'
                                                    }}>Sell</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-purchase__effect-detail"></div>
                                    <div className="btn-purchase__effect-detail--arrow"></div>
                                    <div
                                        className="btn-purchase__info btn-purchase__info--right"
                                    >
                                        <div className="btn-purchase__text_wrapper">
                                            <span
                                                className="dc-text"
                                                style=
                                                {{
                                                    '--text-size': 'var(--text-size-xs)',
                                                    '--text-color': 'var(--text-colored-background)',
                                                    '--text-lh': 'var(--text-lh-m)',
                                                    '--text-weight': 'var(--text-weight-bold)',
                                                    '--text-align': 'var(--text-align-left)'
                                                }}>{symbolInfoTick?.symbol_info_tick.bid}</span>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </fieldset >
                </div >
            </div >
        </div >
    );
};

export default TradeControl;
