import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../styles/Register.module.css'; // Importa un archivo CSS para estilos personalizados
import ThemeToggleButton from '../components/ThemeToggleButton';
import Captcha from '../components/Captcha';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null); // Estado para el valor del captcha

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (!captchaValue) {
            setError('Please complete the CAPTCHA.');
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/users/register/`, {
                username: email,
                email,
                password,
                captchaValue, // Enviar el valor del captcha
            });
            setSuccess(response.data.message);
            setError('');
        } catch (error) {
            setError('Registration failed. ' + (error.response?.data?.error || ''));
        }
    };

    return (
        <div className={styles.registerWrapper}>
        <div className={styles.registerContainer}>
            <h2 className={styles.title}>Register</h2>
            <form onSubmit={handlePasswordReset} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="email" className={styles.label}>Email (Username)</label>
                    <input
                        type="email"
                        className={styles.input}
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="password" className={styles.label}>Password</label>
                    <input
                        type="password"
                        className={styles.input}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword" className={styles.label}>Confirm Password</label>
                    <input
                        type="password"
                        className={styles.input}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm your password"
                        required
                    />
                </div>
                <Captcha setCaptchaValue={setCaptchaValue} />
                <button type="submit" className={styles.submitButton}>Register</button>
            </form>
            {error && <p className={styles.errorText}>{error}</p>}
            {success && <p className={styles.successText}>{success}</p>}
            <div className={styles.linksContainer}>
                <Link to="/login" className={styles.link}>Already have an account? Login here</Link>
            </div>
            <div className={styles.themeToggle}>
                <ThemeToggleButton />
            </div>
        </div>
    </div>
    );
};

export default Register;
