import React from "react";
import classNames from 'classnames';

const IntervalItem = ({ timeframe, isActive, onClick }) => {
    return (
        <div className={classNames("sc-tooltip sc-interval__item sc-tooltip--top", { "sc-interval__item--active": isActive })} onClick={onClick}>
            <div className="sc-inline-loader">
                <span>{timeframe.description}</span>
            </div>
        </div>
    );
};

export default IntervalItem;