// src/components/Footer.js
import React from 'react';
import ThemeToggleButton from './ThemeToggleButton';
import TimezoneClock from './TimezoneClock';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__links footer__links--left">
                <a
                    id="dt_positions_toggle"
                    data-testid="dt_positions_toggle"
                    className="positions-toggle"
                    data-count="0"
                >
                    <i className="bi bi-briefcase"></i>
                </a>
            </div>
            <div data-testid="dt_network_status" className="network-status__wrapper">
                <div className="" data-testid="dt_popover_wrapper">
                    <div className="dc-popover">
                        <div className="dc-popover__target">
                            <i className="dc-popover__target__icon"></i>
                            <div
                                data-testid="dt_network_status_element"
                                className="network-status__circle network-status__circle--online"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-icon-separator"></div>
            <div className="" data-testid="dt_popover_wrapper">
                <div className="dc-popover server-time">
                    <div className="dc-popover__target">
                        <i className="dc-popover__target__icon"></i><TimezoneClock></TimezoneClock>
                    </div>
                </div>
            </div>
            <div className="footer-icon-separator"></div>
            <div className="footer__links">
                {/* <a
                    href="https://wa.me/35699578341"
                    aria-label="WhatsApp"
                    className="footer__link"
                    target="_blank"
                    rel="noreferrer"
                ><div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div></div
                    ></a> */}
                {/* <div>
                    <div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover footer__link">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <a
                    href="https://tts.com/es/responsible"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="footer__link"
                ><div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div></div></a
                > */}
                {/* <a className="footer__link" href="/account/account-limits"
                ><div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div></div
                    ></a> */}
                <div className="footer__links--dark-mode">
                    <div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon">
                                    <ThemeToggleButton></ThemeToggleButton>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-icon-separator"></div>
                {/* <a
                    href="https://tts.com/es/help-centre"
                    rel="noopener noreferrer"
                    target="_blank"
                    id="dt_help_centre"
                    aria-label="Centro de ayuda"
                    className="footer__link"
                ><div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div></div></a
                > */}
                {/* <a id="dt_settings_toggle" data-testid="dt_toggle_settings" className="ic-settings footer__link footer__link">
                    <div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div>
                    </div>
                </a> */}
                <a id="dt_language_settings_toggle" data-testid="dt_toggle_language_settings" className="ic-language footer__link">
                    <div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i><span
                                    className="dc-text ic-settings-language__text">ES</span>
                            </div>
                        </div>
                    </div>
                </a>
                {/* <a data-testid="dt_fullscreen_toggle" className="ic-fullscreen footer__link footer__link" id="dt_fullscreen_toggle">
                    <div className="" data-testid="dt_popover_wrapper">
                        <div className="dc-popover">
                            <div className="dc-popover__target">
                                <i className="dc-popover__target__icon"></i>
                            </div>
                        </div>
                    </div>
                </a> */}
            </div>
        </footer>
    );
};

export default Footer;
