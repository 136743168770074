import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Importar los íconos de Bootstrap

const ThemeToggleButton = () => {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        // Cargar el tema preferido desde el almacenamiento local
        const savedTheme = localStorage.getItem('theme') || 'light';
        document.body.setAttribute('data-bs-theme', savedTheme);
        document.body.classList.remove(`theme--${savedTheme}`)
        document.body.classList.add(`theme--${savedTheme}`)
        setTheme(savedTheme);
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        document.body.setAttribute('data-bs-theme', newTheme);
        document.body.classList.remove(`theme--${theme}`)
        document.body.classList.add(`theme--${newTheme}`)
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme);
        window.dispatchEvent(new Event('storage'));
    };

    return (
       <button
            type="button"
            className="btn" // Puedes ajustar el estilo según sea necesario
            onClick={toggleTheme}
            aria-label="Toggle theme"
        >
            {theme === 'light' ? (
                <i className="bi bi-moon-stars-fill"></i> // Icono de luna para tema claro
            ) : (
                <i className="bi bi-sun" ></i> // Icono de sol para tema oscuro
            )}
        </button>
    );
};

export default ThemeToggleButton;
