import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as echarts from 'echarts';
import { candlesAtom, selectedSymbolAtom, selectedTimeframeAtom } from '../atoms';

const upColor = "#198754";
const downColor = "#dc3545";

const Chart = () => {
    const chartRef = useRef(null);
    let ratesData = useRecoilValue(candlesAtom);
    const symbol = useRecoilValue(selectedSymbolAtom);
    const timeframe = useRecoilValue(selectedTimeframeAtom);
    // const [timeframe, setTimeframe] = useState('H1');

    useEffect(() => {
        // Inicializar el gráfico
        if (!symbol || !timeframe) {
            return;
        }

        const chart = echarts.init(chartRef.current, 'dark');
        const option = {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
            },
            dataZoom: [
                {
                    type: "inside",
                    xAxisIndex: [0, 1],
                    start: 90,
                    end: 100,
                },
            ],
        };
        chart.setOption(option);

        const updateChart = async () => {
            if (!symbol) return;

            try {
                // const apiUrl = process.env.REACT_APP_API_URL;
                // const symbolResponse = await fetch(`${apiUrl}/market-data/symbol-info-tick/${symbol?.name}/`);
                // const symbolData = await symbolResponse.json();
                // now = Math.floor(Date.parse(symbolData.symbol_info_tick.time) / 1000);

                // const ratesResponse = await fetch(`${apiUrl}/market-data/get-rates-from/${symbol?.name}/${timeframe?.name}/${now}/1000/`);
                // const ratesData = await ratesResponse.json();

                const prices = ratesData.rates.map(item => [
                    item.open_price,
                    item.close_price,
                    item.low_price,
                    item.high_price,
                ]);

                const volumes = ratesData.rates.map((item, index) => [
                    index,
                    item.volume,
                    item.open_price > item.close_price ? 1 : -1,
                ]);

                const times = ratesData.rates.map(item => new Date(item.time).toLocaleString());
                const lastClose = ratesData.rates.length > 0 ? ratesData.rates[ratesData.rates.length - 1].close_price : null;

                chart.setOption({
                    backgroundColor: "transparent",
                    animation: false,
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                        },
                        borderWidth: 1,
                        borderColor: "#ccc",
                        padding: 10,
                        textStyle: {
                            color: "#000",
                        },
                    },
                    axisPointer: {
                        link: [
                            {
                                xAxisIndex: "all",
                            },
                        ],
                        label: {
                            backgroundColor: "#777",
                        },
                    },
                    visualMap: {
                        show: false,
                        seriesIndex: 1,
                        dimension: 2,
                        pieces: [
                            {
                                value: 1,
                                color: downColor,
                                colorAlpha: 0.3,
                            },
                            {
                                value: -1,
                                color: upColor,
                                colorAlpha: 0.3,
                            },
                        ],
                    },
                    grid: [
                        {
                            left: "1%",
                            right: "65px",
                            height: "100%",
                            bottom: "25px",
                        },
                        {
                            left: "1%",
                            right: "65px",
                            height: "10%",
                            bottom: "25px",
                        },
                    ],
                    xAxis: [
                        {
                            type: "category",
                            data: times,
                            boundaryGap: false,
                            axisTick: { show: false },
                            axisLine: { onZero: true, show: false },
                            splitLine: { show: true },
                            min: "dataMin",
                            max: "dataMax",
                            axisPointer: {
                                z: 100,
                            },
                        },
                        {
                            type: "category",
                            gridIndex: 1,
                            data: times,
                            boundaryGap: false,
                            axisLine: { onZero: true },
                            axisTick: { show: false },
                            splitLine: { show: false },
                            axisLabel: { show: false },
                            min: "dataMin",
                            max: "dataMax",
                            show: false,
                        },
                    ],
                    yAxis: [
                        {
                            position: 'right',
                            scale: true,
                            splitArea: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                            },
                        },
                        {
                            scale: true,
                            gridIndex: 1,
                            splitNumber: 2,
                            axisLabel: { show: false },
                            axisLine: { show: false },
                            axisTick: { show: false },
                            splitLine: { show: false },
                        },
                    ],
                    series: [
                        {
                            name: symbol.name,
                            type: "candlestick",
                            data: prices,
                            markLine: {
                                label: {
                                    backgroundColor: downColor,
                                    color: '#FFF',
                                    padding: [4, 4, 4, 10],
                                    borderRadius: [20, 0, 0, 20]
                                },
                                symbol: "none",
                                precision: 5,
                                silent: false,
                                lineStyle: {
                                    color: '#F68080',
                                },
                                data: [
                                    {
                                        yAxis: lastClose,
                                    },
                                ],
                            },
                            itemStyle: {
                                color: '#80ED9E',
                                borderColor: upColor,
                                color0: '#F68080',
                                borderColor0: downColor,
                            },
                        },
                        {
                            name: "Volume",
                            type: "bar",
                            xAxisIndex: 1,
                            yAxisIndex: 1,
                            data: volumes,
                        },
                    ],
                    dataZoom: [
                        {
                            end: 100,
                        }
                    ],
                });

                // setTimeout(updateChart, 250);
            } catch (error) {
                console.error("Error updating chart:", error);
                // setTimeout(updateChart, 750);
            }
        };

        updateChart();

        window.onresize = () => {
            chart.resize();
        };

        return () => {

            chart.dispose();
        };
    }, [ratesData, symbol, timeframe]);

    return (
        <div className="chart">
            <div id="chart" ref={chartRef}></div>
        </div>
    );
};

export default Chart;
