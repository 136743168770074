import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/core.main.css'
import '../styles/core.chunk.css'
import '../styles/trader.css'
import '../styles/trader-app.css'
import '../styles/trade-modals.css'
import { ModalProvider } from '../contexts/ModalContext';
import Modal from './Modal';

const Layout = ({ children }) => {

    useEffect(() => {
        document.documentElement.setAttribute('dir', 'ltr');
    }, []);

    return (
        <ModalProvider>
            <div id="deriv_app" className="tts-app">
                <Header></Header>
                <main id="app_contents" className="app-contents app-contents--show-positions-drawer">
                    {children}
                </main>
                <Footer></Footer>
                <Modal></Modal>                
            </div>
        </ModalProvider>
    );
};

export default Layout;
