import React, { useState, useEffect, useContext, useRef } from "react";
import { useRecoilValue, useRecoilState } from 'recoil';
import { symbolsAtom, symbolIndustriesAtom, symbolSectorsAtom, selectedSymbolAtom } from '../atoms';
import classNames from 'classnames';
import MenuItem from "./MenuItem";
import MenuSector from "./MenuSector";
import MenuIndustry from "./MenuIndustry";
import MenuGroup from "./MenuGroup";
import SearchInput from "./SearchInput";
import Dialog from "./Dialog";
import SymbolInfo from "./SymbolInfo";
import { AuthenticationContext } from "../contexts/AuthenticationContext"; // Importa el contexto de autenticación

const Menu = ({ onSymbolSelect }) => {
    const { authTokens } = useContext(AuthenticationContext); // Obtiene el token y el usuario autenticado desde el contexto
    const [enterDone, setEnterDone] = useState(false); // Estado para manejar la clase 'enterDone'
    const [windowHeight, setWindowHeight] = useState(window.innerHeight); // Estado para la altura de la ventana
    const [isLoading, setIsLoading] = useState(true); // Para manejar el estado de carga de los datos
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSymbol, setSelectedSymbol] = useRecoilState(selectedSymbolAtom);

    const menuRef = useRef(null); // Referencia al contenedor del menú

    const sectors = useRecoilValue(symbolSectorsAtom);
    const industries = useRecoilValue(symbolIndustriesAtom);
    const symbols = useRecoilValue(symbolsAtom);

    // Actualizar la altura cuando la ventana cambie de tamaño
    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight); // Actualiza la altura de la ventana
        };

        window.addEventListener('resize', handleResize); // Escucha el cambio de tamaño de la ventana

        // Limpiar el event listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Cargar datos del backend al montar el componente
    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                setIsLoading(true);

                // Cargar símbolo inicial desde localStorage o seleccionar el primero
                if (!selectedSymbol && symbols.length > 0) {
                    localStorage.setItem('selectedSymbol', JSON.stringify(symbols[0]));
                    setSelectedSymbol(symbols[0]); // Cargar el símbolo desde localStorage si existe
                }
                setIsLoading(false);

            } catch (error) {
                console.error('Error cargando los datos del menú:', error);
                setIsLoading(false);
            }
        };

        // Solo cargar los datos si el usuario está autenticado y hay un token disponible
        if (authTokens) {
            fetchMenuData();
        }
    }, [authTokens]); // Dependencia del token

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm.toLowerCase()); // Actualiza el estado de búsqueda
    };

    // Manejar la selección de un símbolo
    const handleSymbolSelect = (symbol) => {
        localStorage.setItem('selectedSymbol', JSON.stringify(symbol)); // Guardar en localStorage
        setSelectedSymbol(symbol);
        onSymbolSelect(symbol);
        setEnterDone(prev => !prev);
    };

    const handleClick = () => {
        setEnterDone(prev => !prev); // Alternar el valor de enterDone entre true y false
    };

    const handleClickOutside = (event) => {
        // Si el clic ocurrió fuera del menú, cerrar el menú
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setEnterDone(false); // Cierra el menú
        }
    };

    // Escuchar clics fuera del menú cuando esté abierto
    useEffect(() => {
        if (enterDone) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [enterDone]);

    const filteredSymbols = symbols.filter(symbol =>
        symbol.name.toLowerCase().includes(searchTerm)
    );

    if (isLoading) {
        return <div>Cargando menú...</div>;
    }

    return (
        <div ref={menuRef} className="ciq-menu ciq-enabled cq-chart-title stx-show cq-symbols-display">
            <div onClick={handleClick} className="cq-menu-btn">
                <div className="cq-symbol-select-btn">
                    <div className="cq-symbol-select-btn__container">
                        <span className="ic-icon"></span>
                        {selectedSymbol && <SymbolInfo />} {/* Cargar el símbolo seleccionado */}
                    </div>
                    <span className="ic-icon cq-symbol-dropdown">
                        {enterDone ? (
                            <i className="bi bi-chevron-up"></i> // Icono hacia arriba si el menú está abierto
                        ) : (
                            <i className="bi bi-chevron-down"></i> // Icono hacia abajo si el menú está cerrado
                        )}
                    </span>
                </div>
            </div>
            <Dialog className={classNames("cq-menu-dropdown", { "cq-menu-dropdown-enter-done": enterDone })}>
                <div className="sc-mcd" style={{ height: `${windowHeight - 180}px` }}>
                    <div className="sc-mcd__tabs">
                        <div className="sc-mcd__tabs__head">
                            Symbols
                        </div>
                        <div className="sc-mcd__tabs__body">
                            <div className="sc-mcd__filter">
                                {sectors.map(sector => (
                                    <MenuSector key={sector.id} id={sector.id} sectorName={sector.name}
                                        selected={selectedSymbol && selectedSymbol.industry.sector.id === sector.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="sc-mcd__content">
                        <div className="sc-mcd__content__head">
                            <SearchInput onSearch={handleSearch} />
                        </div>
                        <div className="sc-mcd__content__body">
                            <div className="sc-scrollbar sc-scrollbar--auto-hide">
                                {industries.map(industry => (
                                    <MenuIndustry key={industry.id} id={industry.id} industryName={industry.name}>
                                        {/* Filtrar y agrupar los símbolos sintéticos y no sintéticos */}
                                        <MenuGroup groupName="Símbolos Sintéticos">
                                            {filteredSymbols
                                                .filter(symbol => symbol.industry.id === industry.id && symbol.is_synthetic)
                                                .map(symbol => (
                                                    <MenuItem key={symbol.id} id={symbol.id} title={symbol.name}
                                                        onClick={() => handleSymbolSelect(symbol)}
                                                        selected={selectedSymbol && selectedSymbol.id === symbol.id} />
                                                ))}
                                        </MenuGroup>

                                        <MenuGroup groupName="Símbolos No Sintéticos">
                                            {filteredSymbols
                                                .filter(symbol => symbol.industry.id === industry.id && !symbol.is_synthetic)
                                                .map(symbol => (
                                                    <MenuItem key={symbol.id} id={symbol.id} title={symbol.name}
                                                        onClick={() => handleSymbolSelect(symbol)}
                                                        selected={selectedSymbol && selectedSymbol.id === symbol.id} />
                                                ))}
                                        </MenuGroup>
                                    </MenuIndustry>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Menu;
