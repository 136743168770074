import React, { useEffect, useState } from 'react';
import Chart from '../components/Chart';
import '../styles/Dashboard.css'
import Layout from '../components/Layout';
import classNames from 'classnames';
import Menu from '../components/Menu';
import ToolbarWidget from '../components/ToolbarWidget';
import PositionList from '../components/PositionList';
import DataFetcher from '../components/DataFetcher';
import { useRecoilValue } from 'recoil';
import { selectedTimeframeAtom } from '../atoms';
import TradeControl from '../components/TradeControl';

const Dashboard = () => {
    const [selectedSymbol, setSelectedSymbol] = useState(null); // Estado para el símbolo seleccionado
    const selectedTimeframe = useRecoilValue(selectedTimeframeAtom); // Estado para el símbolo seleccionado
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light'); // Estado para el tema

    // Actualizar el estado del tema cuando se cambia en localStorage
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            setTheme(storedTheme);
        }
    }, []); // Solo ejecuta cuando se monta el componente

    // Efecto que escucha cambios manuales en el localStorage (cuando cambian desde otro componente)
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedTheme = localStorage.getItem('theme');
            if (updatedTheme) {
                console.log(updatedTheme)
                setTheme(updatedTheme);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        // Agregar la clase al elemento root        
        const rootElement = document.getElementById('root');
        rootElement.classList.add('full-width');
        document.body.classList.add('align-items-start');

        // Limpiar la clase cuando el componente se desmonta
        return () => {
            rootElement.classList.remove('full-width');
            document.body.classList.remove('align-items-start');
        };
    }, []);

    // Función para manejar la selección del símbolo desde el menú
    const handleSymbolSelect = (symbol) => {
        setSelectedSymbol(symbol);
    };
    
    return (
        <Layout>
            <DataFetcher></DataFetcher>
            <div
                data-testid="dt_themed_scrollbars"
                className="dc-themed-scrollbars dc-themed-scrollbars__autohide dc-themed-scrollbars--has-horizontal"
                style={{ maxHeight: 'calc(-84px + 100vh)', maxWidth: 'none' }}
            >
                <div
                    className="trade-container trade-container--accumulators"
                    id="trade_container"
                >
                    <div className="positions-drawer__bg positions-drawer__bg--open"></div>
                    <PositionList></PositionList>
                    <div
                        id="chart_container"
                        className="chart-container"
                        data-testid="dt_div_100_vh"
                    >
                        <div className="chart-container__wrapper">
                            <div id="trade" className={classNames("smartcharts smartcharts--navigation-widget smartcharts-1280", "smartcharts-" + theme)}
                            >
                                <div className="smartcharts-desktop">
                                    <div className="cq-context">
                                        <div className="cq-chart-control-bottom">
                                            <div className="ciq-chart-area">
                                                <div className="ciq-chart">
                                                    <div className="cq-top-ui-widgets">
                                                        <Menu onSymbolSelect={handleSymbolSelect}></Menu>
                                                    </div>
                                                    <Chart symbol={selectedSymbol} timeframe={selectedTimeframe}></Chart>
                                                    <div className="sc-navigation-widget">
                                                        <div
                                                            className="sc-navigation-widget__item sc-navigation-widget__item--scale sc-navigation-widget__item--hidden"
                                                        >
                                                            <span className="ic-icon"
                                                            ></span>
                                                        </div>
                                                        <div
                                                            className="sc-navigation-widget__item sc-navigation-widget__item--zoom"
                                                        >
                                                            <span className="ic-icon"
                                                            ></span>
                                                            <div
                                                                className="sc-tooltip sc-tooltip--right sc-tooltip--enable">
                                                                <div className="active sc-toggle">
                                                                    <span className="ic-icon"
                                                                    ></span>
                                                                </div>
                                                                <div className="sc-tooltip__inner">
                                                                    Mostrar información de precio en el gráfico
                                                                </div>
                                                            </div>
                                                            <span className="ic-icon"
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <ToolbarWidget ></ToolbarWidget>
                                                    <div className="cq-inchart-subholder">
                                                        <div
                                                            className="barrier hide-pricelines"
                                                            style={{ '--shade-color': '#39b19d' }}
                                                        >
                                                            <div
                                                                className="barrier-area barrier-area--zero"
                                                                style={{ transform: 'translateY(361px)' }}
                                                            >
                                                                <div
                                                                    className="chart-line horizontal"
                                                                    style={{ color: 'rgb(255, 255, 255)' }}
                                                                >
                                                                    <div
                                                                        className="drag-line drag-line--zero"
                                                                        style={{
                                                                            borderTopColor: 'rgb(55, 124, 252)',
                                                                            borderTopStyle: 'solid',
                                                                            width: 'calc(100% - 44px)'
                                                                        }}
                                                                    ></div>
                                                                    <div className="draggable-area"></div>
                                                                    <div className="draggable-area-wrapper">
                                                                        <div
                                                                            className="drag-price"
                                                                            style={{
                                                                                backgroundColor: 'rgb(55, 124, 252)',
                                                                                width: '16px',
                                                                                right: '47px',
                                                                            }}
                                                                        >
                                                                            <div className="drag-icon">
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                            <div
                                                                                className="price price--zero"
                                                                                style={{
                                                                                    right: '32px',
                                                                                    color: 'rgb(55, 124, 252)',
                                                                                }}
                                                                            >
                                                                                0.00
                                                                            </div>
                                                                            <div></div>
                                                                        </div>
                                                                        <div
                                                                            className="price-overlay"
                                                                            style={{
                                                                                backgroundColor: 'rgb(55, 124, 252)',
                                                                                width: '50px',
                                                                                right: '3px',
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="barrier-area"
                                                                hidden=""
                                                                style={{
                                                                    transform: 'translateY(827px)'
                                                                }}
                                                            >
                                                                <div
                                                                    className="chart-line horizontal"
                                                                    style={{
                                                                        color: 'rgb(255, 255, 255)'
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="drag-line"
                                                                        style={{
                                                                            borderTopColor: 'rgb(55, 124, 252)',
                                                                            borderTopStyle: 'solid',
                                                                            width: 'calc(100 % - 44px)',
                                                                        }}
                                                                    ></div>
                                                                    <div className="draggable-area"></div>
                                                                    <div className="draggable-area-wrapper">
                                                                        <div
                                                                            className="drag-price"
                                                                            style={{
                                                                                backgroundColor: 'rgb(55, 124, 252)',
                                                                                width: '60px',
                                                                                right: '3px',
                                                                            }}
                                                                        >
                                                                            <div className="drag-icon">
                                                                                <div></div>
                                                                                <div></div>
                                                                                <div></div>
                                                                            </div>
                                                                            <div className="price" style={{
                                                                                right: '0px'
                                                                            }}>
                                                                                -428.12
                                                                            </div>
                                                                            <div></div>
                                                                            <span
                                                                                className="ic-icon arrow-icon"
                                                                                style={{
                                                                                    top: '-41px',
                                                                                    transform: 'rotate(180deg)'
                                                                                }}
                                                                            ></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="shade top-shade hidden"
                                                                style={{
                                                                    top: '-120px'
                                                                }}
                                                            ></div>
                                                            <div
                                                                className="shade bottom-shade hidden"
                                                                style={{
                                                                    top: '-120px'
                                                                }}
                                                            ></div>
                                                            <div
                                                                className="shade between-shade hidden"
                                                                style={{
                                                                    top: '-120px'
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div >
                                                    <div
                                                        className="cq-bottom-ui-widgets"
                                                        style={{
                                                            bottom: '30px'
                                                        }}
                                                    >
                                                        <div className="bottom-widgets">
                                                            <div className="accumulators-stats">
                                                                <div
                                                                    className="accumulators-stats__container--collapsed"
                                                                >
                                                                    <div className="accumulators-stats__title">
                                                                        <span
                                                                            className="dc-text accumulators-stats__title-text"
                                                                            style={{
                                                                                '--text-size': 'var(--text-size-xxs)',
                                                                                '--text-color': 'var(--text-general)',
                                                                                '--text-lh': 'var(--text-lh-m)',
                                                                                '--text-weight': 'var(--text-weight-bold)',
                                                                                '--text-align': 'var(--text-align-left)',
                                                                            }}
                                                                        >Estadísticas</span
                                                                        >
                                                                    </div>
                                                                    <span
                                                                        className="dc-text accumulators-stats__history"
                                                                        style={{
                                                                            '--text-size': 'var(--text-size-xxs)',
                                                                            '--text-color': 'var(--text-general)',
                                                                            '--text-lh': 'var(--text-lh-m)',
                                                                            '--text-weight': 'var(--text-weight-normal)',
                                                                            '--text-align': 'var(--text-align-left)',
                                                                        }}
                                                                    ><div
                                                                        data-testid="dt_accu_stats_history_counter"
                                                                        className="accumulators-stats__history-counter"
                                                                    >
                                                                            39
                                                                            <div
                                                                                className="accumulators-stats__progress-dots"
                                                                            >
                                                                                <span className="dot-1"></span
                                                                                ><span className="dot-2"></span
                                                                                ><span className="dot-3"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            92
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            6
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            21
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            53
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            66
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            4
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            11
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            69
                                                                        </div>
                                                                        <div
                                                                            data-testid="dt_accu_stats_history_counter"
                                                                            className="accumulators-stats__history-counter"
                                                                        >
                                                                            1
                                                                        </div></span
                                                                    >
                                                                </div>
                                                            </div>
                                                        </ div >
                                                    </div >
                                                </div >
                                                <div className="sc-loader">
                                                    <div className="sc-loader-spin"></div>
                                                    <div className="sc-loader-status">
                                                        Retrieving Chart Data...
                                                    </div>
                                                </div>
                                            </div >
                                        </div >
                                    </div >
                                    <div id="smartcharts_modal" className="ciq-modal"></div>
                                </div >
                            </div >
                        </div >
                    </div >
                  <TradeControl></TradeControl>
                </div >
            </div >
            {/* <div className="main-content">
                <Chart className="chart" />
                <Sidebar />
            </div> */}
        </Layout >
    );
};

export default Dashboard;
