import React, { useState, useEffect } from 'react';

const SearchInput = ({ onSearch }) => {
    const [searchValue, setSearchValue] = useState(''); // Estado para el valor de búsqueda

    // Efecto que llama a la función de búsqueda cada vez que cambia el valor del input
    useEffect(() => {
        if (onSearch) {
            const delayDebounceFn = setTimeout(() => {
                // Llamar a la función de búsqueda pasando el valor actual del input
                console.log('Buscando:', searchValue);
                onSearch(searchValue);
            }, 300); // Esperar 300 ms antes de ejecutar la búsqueda para evitar llamadas constantes

            return () => clearTimeout(delayDebounceFn); // Limpiar el timeout al desmontar o actualizar el componente
        }
    }, [searchValue, onSearch]);

    // Función para manejar cambios en el input
    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    // Función para resetear el campo de búsqueda
    const handleReset = () => {
        setSearchValue(''); // Borrar el valor del input
        onSearch(''); // Reiniciar la búsqueda (opcional)
    };

    return (
        <div className="sc-search-input">
            <input
                className="data-hj-whitelist"
                type="text"
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder="Search..."
                value={searchValue}
                onChange={handleInputChange} // Llama a la función que maneja los cambios
            />
            <span className="ic-icon"></span>
            {searchValue && (
                <span className="ic-icon icon-reset" onClick={handleReset}>
                    {/* Este botón resetea el campo de búsqueda */}
                    &#x2715;
                </span>
            )}
        </div>
    );
};

export default SearchInput;
