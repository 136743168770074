import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { accountsAtom, selectedAccountAtom } from '../atoms';
import LogoutButton from './LogoutButton';

const AccountSelector = () => {
    const accounts = useRecoilValue(accountsAtom);
    const { demoAccounts, realAccounts } = accounts.reduce(
        (result, account) => {
            if (account.trade_mode === 0) {
                result.demoAccounts.push(account);
            } else if (account.trade_mode === 2) {
                result.realAccounts.push(account);
            }
            return result;
        },
        { demoAccounts: [], realAccounts: [] }
    );

    const [activeTab, setActiveTab] = useState('real');
    const [selectedAccount, setSelectedAccount] = useRecoilState(selectedAccountAtom);
    const [isAccountSwitcherVisible, setAccountSwitcherVisible] = useState(false);
    const selectedAccounts = activeTab === 'real' ? realAccounts : demoAccounts;
    
    useEffect(() => {
        if (accounts.length > 0) {
            const updatedAccount = accounts.find(account => account.id === selectedAccount?.id);
            if (!updatedAccount) {
                const defaultAccount = accounts[0];
                setSelectedAccount(defaultAccount);
                localStorage.setItem('selectedAccount', JSON.stringify(defaultAccount));
            } else {
                setSelectedAccount(updatedAccount);
            }
        }
    }, [accounts, selectedAccount, setSelectedAccount]);

    const totalBalance = selectedAccounts.reduce((sum, acc) => sum + acc.balance, 0);
    const handleAccountSelect = (account) => {
        setSelectedAccount(account);
        localStorage.setItem('selectedAccount', JSON.stringify(account));
    };
    const formatCurrency = (value, currency) => {
        if (typeof value !== 'number') return value;
        if (!currency || !currency.iso_code) return value;
        const absValue = Math.abs(value);
        const formattedValue = absValue.toLocaleString('en-US', { style: 'currency', currency: currency.iso_code, currencyDisplay: 'symbol' });
        return value < 0 ? `-${formattedValue}` : formattedValue;
    };    

    return (
        <div className="acc-info__wrapper">
            <div className="acc-info__separator"></div>
            <div data-testid="dt_acc_info" id="dt_core_account-info_acc-info" className="acc-info acc-info--is-virtual" onClick={() => setAccountSwitcherVisible(!isAccountSwitcherVisible)}>
                <span className="acc-info__id"></span>
                <div className="acc-info__account-type-and-balance">
                    <p data-testid="dt_balance" className="acc-info__balance">{selectedAccount ? formatCurrency(selectedAccount.balance, selectedAccount.currency) : selectedAccount?.balance} {selectedAccount?.currency?.iso_code}</p>
                </div>
                <i className={`bi bi-chevron-${isAccountSwitcherVisible ? 'up' : 'down'} dc-text`} style={{ fontSize: 'var(--text-size-sm)', '--text-color': 'var(--text-general)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-normal)', '--text-align': 'var(--text-align-left)' }}></i>
            </div>
            <div className={`acc-switcher__wrapper acc-switcher__wrapper--enter-done ${isAccountSwitcherVisible ? '' : 'hidden'}`}>
                <div className="acc-switcher__list" data-testid="acc-switcher">
                    <div className="dc-tabs dc-tabs--acc-switcher__list-tabs dc-tabs--top" style={{ '--tab-width': '50.00%' }}>
                        <div className="dc-tabs__list--header--acc-switcher__list-tabs">
                            <ul className="dc-tabs__list dc-tabs__list--top dc-tabs__list--border-bottom dc-tabs__list--acc-switcher__list-tabs">
                                <li
                                    id="real_account_tab"
                                    className={`dc-tabs__item ${activeTab === 'real' ? 'dc-tabs__active' : ''}`}
                                    onClick={() => { setActiveTab('real'); }}
                                >
                                    Real
                                </li>
                                <li
                                    id="dt_core_account-switcher_demo-tab"
                                    className={`dc-tabs__item ${activeTab === 'demo' ? 'dc-tabs__active' : ''}`}
                                    onClick={() => { setActiveTab('demo'); }}
                                >
                                    Demo
                                </li>
                                <span className="dc-tabs__active-line dc-tabs__active-line--top" style={{ left: activeTab === 'real' ? '0px' : '160px', width: '160px' }}></span>
                            </ul>
                        </div>
                        <div className="dc-tabs__content dc-tabs__content--acc-switcher__list-tabs">
                            <div data-testid="dt_themed_scrollbars" className="dc-themed-scrollbars dc-themed-scrollbars__autohide" style={{ maxHeight: '354px', maxWidth: 'none' }}>
                                <div className="acc-switcher__list-wrapper">
                                    <div className="dc-content-expander__wrapper">
                                        <div className="dc-content-expander dc-content-expander--expanded acc-switcher">
                                            <div className="dc-content-expander__title dc-content-expander__title--spaced">
                                                <span className="dc-text" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-prominent)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-bold)', '--text-align': 'var(--text-align-left)' }}>
                                                    {activeTab === 'real' ? 'Cuentas Reales' : 'Cuentas Demo'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="dc-content-expander__content">
                                            <div className="acc-switcher__accounts">
                                                {selectedAccounts.map((account) => (
                                                    <div key={account.id} id={`dt_${account.id}`} className={`acc-switcher__account ${selectedAccount && selectedAccount.id === account.id ? 'acc-switcher__account--selected' : ''}`} onClick={() => handleAccountSelect(account)}>
                                                        <span className="acc-switcher__id">
                                                            <span>
                                                                {account?.currency?.name}
                                                                <div className="acc-switcher__loginid-text">{account.id}</div>
                                                            </span>
                                                            <span className="dc-text acc-switcher__balance" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-prominent)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-normal)', '--text-align': 'var(--text-align-left)', fontWeight: 'inherit' }}>
                                                                <span data-testid="dt_span">{account?.currency ? `${formatCurrency(account?.balance, account?.currency)} ${account?.currency?.iso_code}` : account.balance}</span>
                                                            </span>
                                                            {/* <div className="account-details">
                                                                <p>Leverage: {account.leverage}x</p>
                                                                <p>Limit Orders: {account.limit_orders}</p>
                                                                <p>Margin: {account.currency ? formatCurrency(account.margin, account.currency) : account.margin}</p>
                                                                <p>Profit: {account.currency ? formatCurrency(account.profit, account.currency) : account.profit}</p>
                                                                <p>Equity: {account.currency ? formatCurrency(account.equity, account.currency) : account.equity}</p>
                                                                <p>Free Margin: {account.currency ? formatCurrency(account.margin_free, account.currency) : account.margin_free}</p>
                                                                <p>Active: {account.is_active ? 'Yes' : 'No'}</p>
                                                            </div> */}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="acc-switcher__separator"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="acc-switcher__separator"></div>
                    <div className="acc-switcher__total">
                        <span className="dc-text" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-prominent)', '--text-lh': 'var(--text-lh-s)', '--text-weight': 'var(--text-weight-bold)', '--text-align': 'var(--text-align-left)' }}>Total de activos</span>
                        <span className="dc-text acc-switcher__balance" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-prominent)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-normal)', '--text-align': 'var(--text-align-left)' }}>
                            <span data-testid="dt_span">{formatCurrency(totalBalance, selectedAccount?.currency)} {selectedAccount?.currency?.iso_code}</span>
                        </span>
                    </div>
                    <span className="dc-text acc-switcher__total-subtitle" style={{ '--text-size': 'var(--text-size-xxxs)', '--text-color': 'var(--text-less-prominent)', '--text-lh': 'var(--text-lh-xs)', '--text-weight': 'var(--text-weight-normal)', '--text-align': 'var(--text-align-left)' }}>Total de activos en sus cuentas.</span>
                    <div className="acc-switcher__separator"></div>
                    {/* <div className="acc-switcher__traders-hub">
                        <a data-testid="dt_link" className="acc-switcher__traders-hub--link">
                            <span className="dc-text acc-switcher__traders-hub--text" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-general)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-normal)', '--text-align': 'var(--text-align-center)' }}>¿Busca cuentas de CFD? Diríjase al Trader's Hub</span>
                        </a>
                    </div> */}
                    <div className="acc-switcher__separator"></div>
                    <div className="acc-switcher__footer">
                        <button className="dc-btn dc-btn--secondary acc-switcher__btn--traders_hub" tabIndex="0" type="submit">
                            <span className="dc-text dc-btn__text" style={{ '--text-size': 'var(--text-size-xs)', '--text-color': 'var(--text-general)', '--text-lh': 'var(--text-lh-m)', '--text-weight': 'var(--text-weight-bold)', '--text-align': 'var(--text-align-center)' }}>Gestionar cuentas</span>
                        </button>
                        <div id="dt_logout_button" className="acc-switcher__logout">
                            <span className="dc-text acc-switcher__logout-text" style={{
                                '--text-size': 'var(--text-size-xs)',
                                '--text-color': 'var(--text-prominent)',
                                '--text-lh': 'var(--text-lh-m)',
                                '--text-weight': 'var(--text-weight-normal)',
                                '--text-align': 'var(--text-align-left)'
                            }}><LogoutButton/></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSelector;
