import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Captcha from '../components/Captcha';
import styles from '../styles/PasswordResetRequest.module.css'; // Importa un archivo CSS para estilos personalizados
import ThemeToggleButton from '../components/ThemeToggleButton';

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null); // Estado para el valor del captcha

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        if (!captchaValue) {
            setError('Please complete the CAPTCHA.');
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/users/password-reset/`, {
                email,
                captchaValue, // Enviar el valor del captcha
            });
            setMessage(response.data.message);
            setError('');
        } catch (error) {
            setError('Password reset failed. ' + (error.response?.data?.error || ''));
        }
    };

    return (
        <div className={styles.passwordResetWrapper}>
            <div className={styles.passwordResetContainer}>
                <h2 className={styles.title}>Reset Password</h2>
                <form onSubmit={handlePasswordReset} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="email" className={styles.label}>Email</label>
                        <input
                            type="email"
                            className={styles.input}
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <Captcha setCaptchaValue={setCaptchaValue} />
                    <button type="submit" className={styles.submitButton}>Reset Password</button>
                </form>
                {error && <p className={styles.errorText}>{error}</p>}
                {message && <p className={styles.successText}>{message}</p>}
                <div className={styles.linksContainer}>
                    <Link to="/login" className={styles.link}>Remembered your password? Login here</Link>
                </div>
                <div className={styles.themeToggle}>
                    <ThemeToggleButton />
                </div>
            </div>
        </div>
    );
};

export default PasswordResetRequest;
