import React, { useEffect, useState, useContext } from "react";
import { useModal } from '../contexts/ModalContext';
import Dialog from "./Dialog";
import { ReactComponent as AreaIcon } from "../svg/area.svg"
import { ReactComponent as VelaIcon } from "../svg/vela.svg"
import { ReactComponent as HuecoIcon } from "../svg/hueco.svg"
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import IntervalItem from "./IntervalItem";
import { useRecoilValue, useRecoilState } from "recoil";
import { timeframesAtom, selectedTimeframeAtom } from "../atoms";

const ToolbarWidget = () => {
    const { authTokens } = useContext(AuthenticationContext);
    const { openModal, closeModal } = useModal();
    const timeframes = useRecoilValue(timeframesAtom)
    const [selectedTimeframe, setSelectedTimeFrame] = useRecoilState(selectedTimeframeAtom);
    const [isLoading, setIsLoading] = useState(true);
    // Cargar datos del backend al montar el componente
    useEffect(() => {
        const fetchChartConfigData = async () => {
            try {
                setIsLoading(true);
                
                // Cargar símbolo inicial desde localStorage o seleccionar el primero
                if (!selectedTimeframe&&timeframes.length > 0) {
                    localStorage.setItem('selectedTimeframe',JSON.stringify(timeframes[0]))
                    setSelectedTimeFrame(timeframes[0]); // Cargar el primer símbolo si no hay en localStorage
                }

                setIsLoading(false);

            } catch (error) {
                console.error('Error cargando los datos del menú:', error);
                setIsLoading(false);
            }
        };

        // Solo cargar los datos si el usuario está autenticado y hay un token disponible
        if (authTokens) {
            fetchChartConfigData();
        }
    }, [authTokens]); // Dependencia del token

    // Manejar la selección de un símbolo
    const handleTimeframeSelect = (timeframe) => {
        closeModal();
        localStorage.setItem('selectedTimeframe', JSON.stringify(timeframe)); // Guardar en localStorage
        setSelectedTimeFrame(timeframe);        
    };

    const handleOpenModal = () => {
        openModal(
            <Dialog className="sc-dialog-enter-done" title={"Graph config"} handleCloseDialog={closeModal}>
                <div className="sc-chart-mode__section">
                    <div className="sc-chart-mode__section__item">
                        <div className="sc-chart-type">
                            <div className="sc-tooltip sc-chart-type__item sc-tooltip--top">
                                <span className="ic-icon ">
                                    <AreaIcon></AreaIcon>
                                </span>
                                <span className="text">Área</span>
                                <div className="sc-tooltip__inner">Disponible solo para intervalos de tiempo sin tick.</div>
                            </div>
                            <div className="sc-tooltip sc-chart-type__item sc-tooltip--top">
                                <span className="ic-icon ">
                                    <VelaIcon></VelaIcon>
                                </span>
                                <span className="text">Vela</span>
                                <div className="sc-tooltip__inner">Disponible solo para intervalos de tiempo sin tick.</div>
                            </div>
                            <div className="sc-tooltip sc-chart-type__item sc-tooltip--top">
                                <span className="ic-icon ">
                                    <HuecoIcon></HuecoIcon>
                                </span>
                                <span className="text">Hueco</span>
                                <div className="sc-tooltip__inner">Disponible solo para intervalos de tiempo sin tick.</div>
                            </div>
                            {/* <div className="sc-tooltip sc-chart-type__item sc-tooltip--top">
                                <span className="ic-icon "></span>
                                <span className="text">OHLC</span>
                                <div className="sc-tooltip__inner">Disponible solo para intervalos de tiempo sin tick.</div>
                            </div> */}
                        </div>
                    </div>
                    <div className="sc-chart-mode__section__item">
                        <div className="sc-interval">
                            <div className="sc-interval__head">
                                <strong>Timeframe</strong>
                            </div>
                            <div className="sc-interval__info">El intervalo de tick solo está disponible para el tipo de gráfico "Área".</div>
                            <div className="sc-interval__content">
                                {timeframes.map(timeframe => (
                                    <IntervalItem
                                        key={timeframe?.name}
                                        timeframe={timeframe}
                                        isActive={timeframe?.name === selectedTimeframe?.name}
                                        onClick={() => handleTimeframeSelect(timeframe)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    };

    return (
        <div className="sc-toolbar-widget">
            <div className="sc-tooltip ciq-menu ciq-display sc-chart-mode ciq-enabled sc-tooltip--right sc-tooltip--enable">
                <div className="cq-menu-btn" onClick={handleOpenModal}>
                    <div className="sc-chart-mode__menu">
                        <span className="sc-chart-mode__menu__timeperiod">{selectedTimeframe?.name}</span>
                        <span className="ic-icon" tooltip-title="Área">
                            <i className="bi bi-graph-up"></i>
                            <br />
                            <span className="ic-subtitle">Área</span>
                        </span>
                    </div>
                </div>
                <div className="sc-tooltip__inner">
                    Tipos de gráficos
                </div>
            </div>
            <div
                className="sc-tooltip ciq-menu sc-studies ciq-enabled sc-tooltip--right sc-tooltip--enable"
            >
                <div className="cq-menu-btn">
                    <div className="sc-studies__menu">
                        <span className="ic-icon"
                        ></span>
                    </div>
                </div>
                <div className="sc-tooltip__inner">Indicadores</div>
            </div>
            <div
                className="sc-tooltip ciq-menu sc-views-menu ciq-enabled sc-tooltip--right sc-tooltip--enable"
            >
                <div className="cq-menu-btn">
                    <div className="sc-views__menu">
                        <span className="ic-icon"
                        ></span>
                    </div>
                </div>
                <div className="sc-tooltip__inner">Plantillas</div>
            </div>
            <div
                className="sc-tooltip ciq-menu sc-dtools ciq-enabled sc-tooltip--right sc-tooltip--enable"
            >
                <div className="cq-menu-btn">
                    <div className="sc-dtools__menu">
                        <span className="ic-icon"
                        ></span>
                    </div>
                </div>
                <div className="sc-tooltip__inner">
                    Herramientas de dibujo
                </div>
            </div>
            <div
                className="sc-tooltip ciq-menu sc-download-menu ciq-enabled sc-tooltip--right sc-tooltip--enable"
            >
                <div className="cq-menu-btn">
                    <div className="sc-download__menu">
                        <span className="ic-icon"
                        ></span>
                    </div>
                </div>
                <div className="sc-tooltip__inner">Descargar</div>
            </div>
        </div>
    );
};

export default ToolbarWidget;