import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Captcha from '../components/Captcha';
import styles from '../styles/TwoFactorAuth.module.css'; // Importa el CSS específico para 2FA
import ThemeToggleButton from '../components/ThemeToggleButton';

const TwoFactorAuth = ({ userId, onSuccess }) => {
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null); // Estado para el valor del captcha

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        if (!captchaValue) {
            setError('Please complete the CAPTCHA.');
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/users/verify-otp/`, {
                user_id: userId,
                otp: otp,
                captchaValue, // Enviar el valor del captcha
            });
            onSuccess(response.data); // Este es el JWT que viene del backend
        } catch (error) {
            setError('Invalid OTP. Please try again.');
        }
    };

    useEffect(() => {
        // Configura el tema al cargar el componente
        const savedTheme = localStorage.getItem('theme') || 'light';
        document.body.setAttribute('data-bs-theme', savedTheme);
    }, []);

    return (
        <div className={styles.twoFactorAuthContainer}> {/* Contenedor principal */}
            <h2 className="text-center">Enter OTP</h2>
            <form onSubmit={handleVerifyOtp} className="border p-4 rounded shadow">
                <div className="mb-3">
                    <label htmlFor="otp" className="form-label">OTP</label>
                    <input
                        type="text"
                        className="form-control"
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        required
                    />
                </div>
                <Captcha setCaptchaValue={setCaptchaValue} />
                <button type="submit" className="btn btn-primary w-100 mt-3">Verify</button>
            </form>
            {error && <p className="text-danger text-center mt-3">{error}</p>}
            <div className="text-center mt-3">
                <ThemeToggleButton />
            </div>
        </div>
    );
};

export default TwoFactorAuth;
