import React from 'react';
import classNames from 'classnames';

const MenuItem = ({ id, title, onClick, selected }) => {
    return (
        <div className={classNames('sc-mcd__item', { 'sc-mcd__item--selected': selected })}
            onClick={onClick}>
            <div className="sc-mcd__item__name">
                <span className="ic-wld">
                    <span className="ic-icon">
                        <i className="bi bi-airplane"></i>
                    </span>
                    <span className="ic-icon">
                        <i className="bi bi-airplane"></i>
                    </span>
                </span>{title}
            </div>
            <div className="sc-mcd__item__detail">
                <span className="ic-icon ciq-favorite">
                    <i className="bi bi-star"></i>
                </span>
            </div>
        </div>
    );
};

export default MenuItem;
