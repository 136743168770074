import React, { useEffect } from 'react';

const ThemeProvider = ({ children }) => {
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        document.body.setAttribute('data-bs-theme', savedTheme);
    }, []);

    return <>{children}</>; // Renderiza los hijos
};

export default ThemeProvider;
