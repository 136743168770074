import React, { useState } from 'react';
import { FlexRow } from './FlexRow';
import classNames from 'classnames';
import { positionsAtom, selectedSymbolAtom, authTokenAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import axios from 'axios';

const PositionDetails = ({ position, positionIndex }) => {
    const authToken = useRecoilValue(authTokenAtom);
    
    const handleClosePosition = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(
                `${apiUrl}/market-data/orders/close/${position.id}/`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                }
            );
            console.log('Position closed:', response.data);
            // Aquí podrías agregar lógica adicional para actualizar la UI después de cerrar la posición, por ejemplo, actualizar la lista de posiciones.
        } catch (error) {
            if (error.response) {
                console.error('Error closing position:', error.response.data.error);
                alert(`Error al cerrar la posición: ${error.response.data.error}`);
            } else {
                console.error('Error closing position:', error);
                alert(`Error al cerrar la posición: ${error.message}`);
            }
        }
    };

    return (
        <div style={{
            height: position.state === 0 ? "384px" : "auto",
            left: "0px",
            position: "absolute",
            top: (positionIndex * 384) + "px",
            width: "100%"
        }}>
            <div className="data-list__row--wrapper" style={{ paddingBottom: "8px" }}>
                <div className="data-list__item--wrapper data-list__item--dynamic-height-wrapper">
                    <div className="data-list__item">
                        <div className="dc-contract-card__wrapper">
                            {position.state === 1 && (<div id="dc_contract_card_258296025788_result" className="dc-contract-card__result dc-result__positions-overlay dc-contract-card__result--lost dc-contract-card__result--enter-done">
                                <span id="dc_contract_card_258296025788_result_close_icon" className="dc-result__close-btn">
                                </span>
                                <a className="dc-result__caption-wrapper" href="/contract/258296025788">
                                </a>

                                <div className="dc-result__content">
                                    <span className="dc-result__caption dc-result__caption--lost">Closed</span>
                                    <span className="dc-text" style={{
                                        "--text-size": "var(--text-size-s)",
                                        "--text-color": "var(--text-loss-danger)",
                                        "--text-lh": "var(--text-lh-xxl)",
                                        "--text-weight": "var(--text-weight-bold)",
                                        "--text-align": "var(--text-align-left)"
                                    }}>-<span data-testid="dt_span">{position.profit}</span>
                                    </span>
                                </div>

                            </div>
                            )}
                            <div id={"dc_contract_card_" + position.id}>
                                <a className={classNames("dc-contract-card", { "dc-contract-card--green": position.profit > 0, "dc-contract-card--red": position.profit <= 0 })} href="/contract/258296025788">
                                    <div className="dc-contract-card__grid dc-contract-card__grid-underlying-trade dc-contract-card__grid-underlying-trade--trader dc-contract-card__grid-underlying-trade--trader--sold">
                                        <div id="dc-contract_card_underlying_label" className="dc-contract-card__underlying-name">
                                            <span className="dc-text dc-contract-card__symbol" style={{
                                                "--text-size": "var(--text-size-xxs)",
                                                "--text-color": "var(--text-general)",
                                                "--text-lh": "var(--text-lh-m)",
                                                "--text-weight": "var(--text-weight-bold)",
                                                "--text-align": "var(--text-align-left)"
                                            }}>{position.symbol}</span>
                                        </div>
                                        <div id="dc-contract_card_type_label" className="dc-contract-card__type">
                                            <div className="dc-contract-type">
                                                <div className="dc-contract-type__type-wrapper">

                                                </div>
                                                <div className="dc-contract-type__type-label dc-contract-type__type-label--multipliers">
                                                    <div> {position.type === 1 ? 'Sell' : 'Buy'}</div>
                                                    <div className="dc-contract-type__type-label-trade-param">
                                                        {position.type === 1 ? (
                                                            <><i className="bi bi-arrow-down-right"></i>
                                                                <i className="bi bi-arrow-down-right"></i>
                                                                <i className="bi bi-arrow-down-right"></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                                <i className="bi bi-arrow-up-right"></i>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dc-progress-slider--completed">
                                    </div>
                                    <span className="dc-text dc-currency-badge" style={{
                                        "--text-size": "var(--text-size-xxxs)",
                                        "--text-color": "var(--text-colored-background)",
                                        "--text-lh": "var(--text-lh-unset)",
                                        "--text-weight": "var(--text-weight-bold)",
                                        "--text-align": "var(--text-align-left)"
                                    }}>{position.id}</span>
                                    <div className="dc-contract-card-items-wrapper" data-testid="dt_multiplier_card_body">
                                        <div className="dc-contract-card-item dc-contract-card__stake">
                                            <div className="dc-contract-card-item__header">Open Price:</div>
                                            <div className="dc-contract-card-item__body">
                                                <span data-testid="dt_span">{position.open_price} </span>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__current-stake">
                                            <div className="dc-contract-card-item__header">Current Price:</div>
                                            <div className="dc-contract-card-item__body">
                                                <div className={classNames({ "dc-contract-card--loss": position.profit < 0, "dc-contract-card--profit": position.profit >= 0 })}>
                                                    <span data-testid="dt_span">{position.current_price} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__deal-cancel-fee dc-contract-card__deal-cancel-fee__disabled">
                                            <div className="dc-contract-card-item__header">Time:</div>
                                            <div className="dc-contract-card-item__body">{new Date(position.time).toLocaleString()}</div>
                                        </div>
                                        <div className="dc-contract-card-item dc-contract-card__buy-price">
                                            <div className="dc-contract-card-item__header">Volume:</div>
                                            <div className="dc-contract-card-item__body">
                                                <span data-testid="dt_span">{position.volume} </span>
                                            </div>
                                        </div>
                                        <div className="dc-contract-card__limit-order-info">
                                            <div className="dc-contract-card-item dc-contract-card__take-profit">
                                                <div className="dc-contract-card-item__header">Take profit:</div>
                                                <div className="dc-contract-card-item__body">
                                                    <span data-testid="dt_span">{position.tp} </span>
                                                </div>
                                            </div>
                                            <div className="dc-contract-card-item dc-contract-card__stop-loss">
                                                <div className="dc-contract-card-item__header">Stop loss:</div>
                                                <div className="dc-contract-card-item__body">
                                                    <span data-testid="dt_span">{position.sl} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dc-contract-card-item dc-contract-card-item__total-profit-loss">
                                        <div className="dc-contract-card-item__header">Profit:</div>
                                        <div className={classNames("dc-contract-card-item__body", { "dc-contract-card-item__body--loss": position.profit <= 0, "dc-contract-card-item__body--profit": position.profit > 0, })}>
                                            <span data-testid="dt_span">{Math.abs(position.profit)} </span>
                                        </div>
                                    </div>
                                </a>
                                <div className="dc-contract-card-item__footer">
                                    <div className="dc-contract-card__sell-button dc-contract-card__sell-button--positions">
                                        <button
                                            id={"dc_contract_card_" + position.id + "_button"}
                                            className="dc-btn dc-btn--secondary dc-btn--sell"
                                            tabIndex="0"
                                            type="button"
                                            onClick={handleClosePosition}
                                        >
                                            <span className="dc-text dc-btn__text" style={{
                                                "--text-size": "var(--text-size-xs)",
                                                "--text-color": "var(--text-general)",
                                                "--text-lh": "var(--text-lh-m)",
                                                "--text-weight": "var(--text-weight-bold)",
                                                "--text-align": "var(--text-align-center)"
                                            }}>Cerrar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PositionList = () => {
    const [filter, setFilter] = useState({
        user: '',
        symbol: '',
        direction: '',
    });
    const positions = useRecoilValue(positionsAtom);
    const selectedSymbol = useRecoilValue(selectedSymbolAtom);

    const handleFilterChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };
    const filteredPositions = positions.filter((position) => {
        return (
            (position.symbol === selectedSymbol?.name)
        );
    });

    return (
        <div id="dt_positions_drawer" className="positions-drawer positions-drawer--open">
            <div className="positions-drawer__header">
                <span className="dc-text" style={{
                    "--text-size": "var(--text-size-xs)",
                    "--text-color": "var(--text-prominent)",
                    "--text-lh": "var(--text-lh-m)",
                    "--text-weight": "var(--text-weight-bold)",
                    "--text-align": "var(--text-align-left)"
                }}>Open positions</span>
                <div id="dt_positions_drawer_close_icon" className="positions-drawer__icon-close">

                </div>
            </div>
            <div className="positions-drawer__body">
                <div data-testid="dt_data_list" className="data-list">
                    <div className="data-list__body-wrapper">
                        <div className="data-list__body" style={{ position: "relative" }}>
                            <div style={{
                                overflow: "visible",
                                height: "0px",
                                width: "0px"
                            }}>
                                <div style={{
                                    height: "calc(-225px + 100vh)",
                                    width: "238px"
                                }}>
                                    <div data-testid="dt_themed_scrollbars" className="dc-themed-scrollbars dc-themed-scrollbars__autohide" style={{
                                        maxHeight: "100%",
                                        maxWidth: "none"
                                    }}>
                                        <div aria-label="grid" aria-readonly="true" className="ReactVirtualized__Grid ReactVirtualized__List" role="grid" tabIndex="0" style={{
                                            boxSizing: "border-box",
                                            direction: "ltr",
                                            height: "auto",
                                            position: "relative",
                                            width: "238px",
                                            willChange: "transform",
                                            overflow: "auto"
                                        }}>
                                            <div className="ReactVirtualized__Grid__innerScrollContainer" role="rowgroup" style={{
                                                width: "auto",
                                                height: (filteredPositions.length * 384) + "px",
                                                maxWidth: "238px",
                                                maxHeight: (filteredPositions.length * 384) + "px",
                                                overflow: "hidden",
                                                position: "relative"
                                            }}>
                                                {filteredPositions
                                                    .sort((a, b) => a.id - b.id)
                                                    .map((position, index) => (
                                                        <FlexRow key={position.id}>
                                                            <PositionDetails position={position} positionIndex={index} />
                                                        </FlexRow>
                                                    ))}
                                                {/* <div style={{
                                                    height: "384px",
                                                    left: "0px",
                                                    position: "absolute",
                                                    top: "315px",
                                                    width: "100%"
                                                }}>
                                                    <div className="data-list__row--wrapper" style={{ paddingBottom: "8px" }}>
                                                        <div className="data-list__item--wrapper data-list__item--dynamic-height-wrapper">
                                                            <div className="data-list__item">
                                                                <div className="dc-contract-card__wrapper">
                                                                    <div id="dc_contract_card_258294919888">
                                                                        <a className="dc-contract-card dc-contract-card--green" href="/contract/258294919888">
                                                                            <div className="dc-contract-card__grid dc-contract-card__grid-underlying-trade dc-contract-card__grid-underlying-trade--trader">
                                                                                <div id="dc-contract_card_underlying_label" className="dc-contract-card__underlying-name">

                                                                                    <span className="dc-text dc-contract-card__symbol" style={{
                                                                                        "--text-size": "var(--text-size-xxs)",
                                                                                        "--text-color": "var(--text-general)",
                                                                                        "--text-lh": "var(--text-lh-m)",
                                                                                        "--text-weight": "var(--text-weight-bold)",
                                                                                        "--text-align": "var(--text-align-left)"
                                                                                    }}>BTC/USD</span>
                                                                                </div>
                                                                                <div id="dc-contract_card_type_label" className="dc-contract-card__type">
                                                                                    <div className="dc-contract-type">
                                                                                        <div className="dc-contract-type__type-wrapper">

                                                                                        </div>
                                                                                        <div className="dc-contract-type__type-label dc-contract-type__type-label--multipliers">
                                                                                            <div>Multipliers</div>
                                                                                            <div className="dc-contract-type__type-label-trade-param">Up x50</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dc-progress-slider">
                                                                                <span className="dc-text dc-progress-slider__remaining-time" style={{
                                                                                    "--text-size": "var(--text-size-xxxs)",
                                                                                    "--text-color": "var(--text-general)",
                                                                                    "--text-lh": "var(--text-lh-m)",
                                                                                    "--text-weight": "var(--text-weight-normal)",
                                                                                    "--text-align": "var(--text-align-left)"
                                                                                }}>
                                                                                    <div className="dc-remaining-time">7 días 01:21:56</div>
                                                                                </span>
                                                                                <div className="dc-progress-slider__track">
                                                                                    <div className="dc-progress-slider__line dc-progress-slider__line--green" style={{ width: "98%" }}>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <span className="dc-text dc-currency-badge" style={{
                                                                                "--text-size": "var(--text-size-xxxs)"
                                                                                , "--text-color": "var(--text-colored-background)",
                                                                                "--text-lh": "var(--text-lh-unset)",
                                                                                "--text-weight": "var(--text-weight-bold)",
                                                                                "--text-align": "var(--text-align-left)"
                                                                            }}>USD</span>
                                                                            <div className="dc-contract-card-items-wrapper" data-testid="dt_multiplier_card_body">
                                                                                <div className="dc-contract-card-item dc-contract-card__stake">
                                                                                    <div className="dc-contract-card-item__header">Costo del contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <span data-testid="dt_span">10.00 </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__current-stake">
                                                                                    <div className="dc-contract-card-item__header">Valor del contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <div className="dc-contract-card--profit">
                                                                                            <span data-testid="dt_span">11.63 </span>
                                                                                        </div>
                                                                                        <div className="dc-contract-card__indicative--movement" data-testid="dt_arrow_indicator">

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__deal-cancel-fee dc-contract-card__deal-cancel-fee__disabled">
                                                                                    <div className="dc-contract-card-item__header">Cuota por cancelación de contrato:</div>
                                                                                    <div className="dc-contract-card-item__body">-</div>
                                                                                </div>
                                                                                <div className="dc-contract-card-item dc-contract-card__buy-price">
                                                                                    <div className="dc-contract-card-item__header">Inversión:</div>
                                                                                    <div className="dc-contract-card-item__body">
                                                                                        <span data-testid="dt_span">10.00 </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="dc-contract-card__limit-order-info">
                                                                                    <div className="dc-contract-card-item dc-contract-card__take-profit">
                                                                                        <div className="dc-contract-card-item__header">Take profit:</div>
                                                                                        <div className="dc-contract-card-item__body">
                                                                                            <span data-testid="dt_span">4.00 </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="dc-contract-card-item dc-contract-card__stop-loss">
                                                                                        <div className="dc-contract-card-item__header">Stop loss:</div>
                                                                                        <div className="dc-contract-card-item__body">
                                                                                            <strong>-</strong>
                                                                                            <span data-testid="dt_span">3.00 </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button className="dc-contract-card-dialog-toggle">

                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dc-contract-card-item dc-contract-card-item__total-profit-loss">
                                                                                <div className="dc-contract-card-item__header">Ganancia/pérdida total:</div>
                                                                                <div className="dc-contract-card-item__body dc-contract-card-item__body--profit">
                                                                                    <span data-testid="dt_span">1.63 </span>
                                                                                    <div className="dc-contract-card__indicative--movement" data-testid="dt_arrow_indicator">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div className="dc-contract-card-item__footer">
                                                                            <div className="dc-contract-card__sell-button dc-contract-card__sell-button--positions">
                                                                                <button id="dc_contract_card_258294919888_button" className="dc-btn dc-btn--secondary dc-btn--sell" tabIndex="0" type="submit">
                                                                                    <span className="dc-text dc-btn__text" style={{
                                                                                        "--text-size": "var(--text-size-xs)",
                                                                                        "--text-color": "var(--text-general)",
                                                                                        "--text-lh": "var(--text-lh-m)",
                                                                                        "--text-weight": "var(--text-weight-bold)",
                                                                                        "--text-align": "var(--text-align-center)"
                                                                                    }}>Cerrar</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="positions-drawer__footer">
                <a className="dc-btn dc-btn--secondary dc-btn__large" id="dt_positions_drawer_report_button" href="/reports">
                    <span className="dc-text" style={{
                        "--text-size": "var(--text-size-xs)",
                        "--text-color": "var(--text-general)",
                        "--text-lh": "var(--text-lh-m)",
                        "--text-weight": "var(--text-weight-bold)",
                        "--text-align": "var(--text-align-left)"
                    }}>Ir a Informes</span>
                </a>
            </div>
        </div>
    );
};

export default PositionList;
