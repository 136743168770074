import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Captcha from '../components/Captcha';
import styles from '../styles/PasswordResetConfirm.module.css'; // Importa un archivo CSS para estilos personalizados
import ThemeToggleButton from '../components/ThemeToggleButton';

const PasswordResetConfirm = () => {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null); // Estado para el valor del captcha

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        if (!captchaValue) {
            setError('Please complete the CAPTCHA.');
            return;
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/users/reset-password/${uidb64}/${token}/`, {
                password,
                captchaValue, // Enviar el valor del captcha
            });
            setMessage(response.data.message);
            setError('');
        } catch (error) {
            setError('Password reset failed. ' + (error.response?.data?.error || ''));
        }
    };

    return (
        <div className={styles.passwordResetWrapper}>
        <div className={styles.passwordResetContainer}>
            <h2 className={styles.title}>Reset Password</h2>
            <form onSubmit={handlePasswordReset} className={styles.form}>
                <div className={styles.formGroup}>
                    <label htmlFor="password" className={styles.label}>New Password</label>
                    <input
                        type="password"
                        className={styles.input}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter new password"
                        required
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword" className={styles.label}>Confirm New Password</label>
                    <input
                        type="password"
                        className={styles.input}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm new password"
                        required
                    />
                </div>
                <Captcha setCaptchaValue={setCaptchaValue} />
                <button type="submit" className={styles.submitButton}>Reset Password</button>
            </form>
            {error && <p className={styles.errorText}>{error}</p>}
            {message && <p className={styles.successText}>{message}</p>}
            <div className={styles.linksContainer}>
                <Link to="/login" className={styles.link}>Remembered your password? Login here</Link>
            </div>
            <div className={styles.themeToggle}>
                <ThemeToggleButton />
            </div>
        </div>
    </div>
    );
};

export default PasswordResetConfirm;
