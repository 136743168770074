import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ setCaptchaValue }) => {
    const [captchaKey, setCaptchaKey] = useState('');
    
    useEffect(() => {
        const fetchCaptchaKey = async () => {
            const localKey = localStorage.getItem('recaptcha_site_key'); // Check localStorage for the key
            const apiUrl = process.env.REACT_APP_API_URL;
            if (localKey) {
                setCaptchaKey(localKey); // Use the key from localStorage
            } else {
                try {
                    const response = await fetch(`${apiUrl}/recaptcha/recaptcha-key/`); // Adjust the URL according to your configuration
                    const data = await response.json();
                    setCaptchaKey(data.site_key); // Set the fetched key
                    localStorage.setItem('recaptcha_site_key', data.site_key); // Store the key in localStorage
                } catch (error) {
                    console.error('Error fetching reCAPTCHA key:', error);
                }
            }
        };

        fetchCaptchaKey();
    }, []);

    return (
        <div>
            {captchaKey && ( // Render reCAPTCHA only if the key is available
                <ReCAPTCHA
                    sitekey={captchaKey} // Use the fetched key
                    onChange={(value) => setCaptchaValue(value)} // Handle the captcha value
                />
            )}
        </div>
    );
};

export default Captcha;
