import React from "react";
import classNames from 'classnames';

const MenuIndustry = ({ id, industryName, className, children }) => {
    return (
        <div className={classNames('sc-mcd__category__content', className)}>
            <div className="subcategory">
                {industryName}
            </div>
            {children}
        </div>
    );
};

export default MenuIndustry;