import React from "react";

const MenuGroup = ({ children }) => {
    return (
        <div className="sc-mcd__category">
            {children}
        </div>
    );
};

export default MenuGroup;