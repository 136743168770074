import React, { useState, useEffect } from 'react';
import { useModal } from '../contexts/ModalContext';

const Modal = () => {
    const { isOpen, modalContent } = useModal();
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    // Actualizar el estado del tema cuando se cambia en localStorage
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            setTheme(storedTheme);
        }
    }, []); // Solo ejecuta cuando se monta el componente

    // Efecto que escucha cambios manuales en el localStorage (cuando cambian desde otro componente)
    useEffect(() => {
        const handleStorageChange = () => {
            const updatedTheme = localStorage.getItem('theme');
            if (updatedTheme) {
                console.log(updatedTheme)
                setTheme(updatedTheme);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    if (!isOpen) return null; // No renderiza nada si el modal no está abierto

    return (
        <div id="modal_root" className="modal-root">
            <div className={"smartcharts-" + theme}>
                <div className="smartcharts-desktop">
                    <div className="cq-modal-dropdown ciq-display sc-chart-mode stxMenuActive">
                        <div className="cq-modal__overlay">
                            {modalContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
