import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import TwoFactorAuth from './TwoFactorAuth';
import Captcha from '../components/Captcha'; // Importa el nuevo componente de captcha
import styles from '../styles/Login.module.css'; // Importa un archivo CSS para estilos personalizados
import ThemeToggleButton from '../components/ThemeToggleButton';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [userId, setUserId] = useState(null);
    const [is2FARequired, setIs2FARequired] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null); // Estado para el valor del captcha
    const { login, setAuthTokens, setRefreshToken } = useContext(AuthenticationContext);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!captchaValue) {
            setError('Please complete the CAPTCHA.');
            return;
        }

        try {
            const response = await login(email, password, captchaValue); // Enviar el valor del captcha

            if (response && response.status === 202) { // 2FA requerido
                setUserId(response.data.user_id);
                setIs2FARequired(true);
            } else {
                navigate('/'); // Redirige al home después de un login exitoso
            }
        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    const handle2FASuccess = (data) => {
        setAuthTokens(data.access);
        setRefreshToken(data.refresh);
        localStorage.setItem('token', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        navigate('/'); // Redirige al home después de la verificación 2FA
    };

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.loginContainer}>
                {is2FARequired ? (
                    <TwoFactorAuth userId={userId} onSuccess={handle2FASuccess} />
                ) : (
                    <>
                        <h2 className={styles.title}>Login</h2>
                        <form onSubmit={handleLogin} className={styles.form}>
                            <div className={styles.formGroup}>
                                <label htmlFor="email" className={styles.label}>Email</label>
                                <input
                                    type="email"
                                    className={styles.input}
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="password" className={styles.label}>Password</label>
                                <input
                                    type="password"
                                    className={styles.input}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                    required
                                />
                            </div>
                            <Captcha setCaptchaValue={setCaptchaValue} /> {/* Renderiza el componente de captcha */}
                            {error && <p className={styles.errorText}>{error}</p>}
                            <button type="submit" className={styles.submitButton}>Login</button>
                        </form>
                        <div className={styles.linksContainer}>
                            <p>
                                Don't have an account? <Link to="/register" className={styles.link}>Register here</Link>
                            </p>
                            <p>
                                Forgot your password? <Link to="/password-reset" className={styles.link}>Reset it here</Link>
                            </p>
                        </div>
                        <div className={styles.themeToggle}>
                            <ThemeToggleButton />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
