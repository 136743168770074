import React from "react";
import classNames from 'classnames';
import { useRecoilValue } from "recoil";
import { symbolInfoTickAtom } from "../atoms";

const SymbolInfo = () => {
    const symbolInfoTick = useRecoilValue(symbolInfoTickAtom);

    return (
        <div className="cq-symbol-info">
            <div className="cq-symbol">
                {symbolInfoTick?.symbol}
            </div>
            <div className="cq-chart-price">
                <div
                    className="cq-animated-price cq-current-price cq-up" style={{color:"var(--text-loss-danger)"}}
                >
                    {symbolInfoTick?.symbol_info_tick.bid}
                </div>
                <div className="divider"> - </div>
                <div
                    className="cq-animated-price cq-current-price cq-up" style={{color:"var(--text-profit-success)"}}
                >
                    {symbolInfoTick?.symbol_info_tick.ask}
                </div>                
                {/* <div className={classNames("cq-change", { "up": symbolInfoTick?.todayChange > 0, "down": symbolInfoTick?.todayChange <= 0 })}>
                    <span className="cq-todays-change">
                        {symbolInfoTick?.todayChange}
                    </span>
                    <span className="cq-todays-change-percent">
                        ({symbolInfoTick?.todayChangePercent})
                    </span>&nbsp;
                </div> */}
            </div>
        </div>
    );
};

export default SymbolInfo