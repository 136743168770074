import React, { useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Asegúrate de que los íconos estén disponibles

const LogoutButton = () => {
    const { logout } = useContext(AuthenticationContext);

    const handleLogout = () => {
        logout();
        // Aquí puedes redirigir al usuario a la página de login o a donde desees
        window.location.href = '/login'; // Cambia la ruta según tu configuración
    };

    return (
        <span className="nav-link" style={{ cursor: 'pointer' }} onClick={handleLogout} aria-label="Logout">
            <i className="bi bi-box-arrow-right" style={{ marginRight: '5px' }}></i> {/* Ícono de logoff */}
            Logout
        </span>
    );
};

export default LogoutButton;
