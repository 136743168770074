import React, { useState, useEffect } from 'react';

const TimezoneClock = () => {
    const [time, setTime] = useState(new Date());
    const [isGMT, setIsGMT] = useState(false);

    // Actualiza la hora cada segundo
    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000);

        // Limpiar el intervalo al desmontar el componente
        return () => clearInterval(timer);
    }, []);

    // Formatea la hora dependiendo si está en GMT o en el uso horario del navegador
    const getFormattedTime = () => {
        if (isGMT) {
            return time.toUTCString(); // Hora en formato GMT
        } else {
            return time.toLocaleString(); // Hora en el uso horario del navegador
        }
    };

    // Cambiar entre GMT y la hora local del navegador
    const toggleTimezone = () => {
        setIsGMT(!isGMT);
    };

    return (
        <button onClick={toggleTimezone}>
            {/* {isGMT ? 'Cambiar a Hora Local' : 'Cambiar a GMT'} */}
            {getFormattedTime()}
        </button>
    );
};

export default TimezoneClock;
